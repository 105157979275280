.logo-row {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 40px;
    width: 40px;
    margin: 1rem;
  }
}

.footer-copyright {
  text-align: center;
  padding: 0.5rem 0 1.5rem 0;
  color: $dark-muted;
  font-weight: 600;
}

footer {
  padding-top: 4rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.5+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */

  .social-ul {
    display: none;

    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .social-li {
    margin: 20px;
  }

  .footer-mail {
    display: none;

    @media (max-width: 767px) {
      display: block;
      text-align: center;
      color: $dark-muted;
      text-decoration: none;
    }
  }
}
