.section-projects {
  p {
    line-height: 1.8;
    font-size: 0.95rem;
    width: 100%;
  }
}

.featuredProject {
  display: flex;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 991px) {
    margin: 40px auto;
  }

  @media (max-width: 991px) {
    position: relative;
    margin-bottom: 50px;
    padding: 0;
  }

  &:hover .featuredProject__img::before {
    background: transparent;
  }
}

.featuredProject__img {
  flex: 1 1 50%;
  position: relative;

  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.featuredProject__img::before {
  position: absolute;
  top: 0;
  content: "";
  background: rgba(10, 25, 47, 0.35);
  left: 0;
  height: 100%;
  width: 100%;
  transition: background 0.3s linear;

}


@media (max-width: 991px) {
  .featuredProject__img::before,
  .featuredProject:hover .featuredProject__img::before {
    background: rgba(10, 25, 47, 0.85);
  } 
}

.featuredProject__detail {
  flex: 1 1 50%;

  @media (max-width: 991px) {
    flex: 1 1 100%;
    position: relative;
  }
}

.featuredProject.right {
  .featuredProject__detail {
    padding: 0 0 0 30px;

    @media (max-width: 991px) {
      padding: 30px;
    }

    @media (max-width: 767px) {
      padding: 20px 10px;
    }
  }
}

.featuredProject.left {
  .featuredProject__detail {
    padding: 0 30px 0 0;
    @media (max-width: 991px) {
      padding: 30px;
    }

    @media (max-width: 767px) {
      padding: 20px 10px;
    }
  }

  .featuredProject__img {
    order: 1;
  }
}

.featuredProject__img {
  box-shadow: 0px 11px 27px 0px rgba(0, 0, 0, 0.3);

  img {
    max-width: 100%;
    @media (max-width: 991px) {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 8px;
    }
  }
}

.project__links {
  display: flex;
  align-items: center;
}

.project-link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.link-img svg {
  width: 20px;
  height: 20px;
}

.link-img svg,
.link-img svg path {
  fill: #fff;
}

.link-img {
  margin-right: 8px;
}

.link-text a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.project-link:first-child {
  margin-right: 30px;
}

.featuredProject__name {
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.featuredProject__name::after {
  content: "";
  position: relative;
  left: 0;
  width: 50px;
  height: 2px;
  background: $dark-accent;
  display: block;
  margin-top: 12px;
}

.featuredProject__desc {
  background: rgba(16, 38, 71, 0.75);
  padding: 30px 25px;
  margin: 30px 0;
  border-radius: 8px;
  box-shadow: 0px 11px 27px 0px rgba(0, 0, 0, 0.18);
  font-size: 0.98rem;
  line-height: 1.8;
}

.other-projects {
  .section-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.8rem;
    color: #fff;
    margin-top: 3rem;

    @media (max-width: 991px) {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }
}

.other-projects-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.other-project__title {
  font-size: 1.3rem;
  font-weight: 600;
  z-index: 1;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s linear;
}

.other-project__desc {
  margin: 1.2rem 0;
  font-size: 0.95rem;
  line-height: 1.6;
  z-index: 1;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.3s linear;
}

.other-project {
  width: 27%;
  margin: 2% 3%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  box-shadow: 0px 11px 27px 0px rgba(0, 0, 0, 0.3);
  position: relative;

  @media (max-width: 991px) {
    width: 100%;
    margin: 2% auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.55+0,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.55) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.55) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.55) 0%,
      rgba(0, 0, 0, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s linear;

    @media (max-width: 991px) {
      opacity: 1;
    }
  }

  .project__links {
    transform-origin: left;
    z-index: 1;
    transform: scale(0.85) translateY(100%);
    opacity: 0;
    transition: all 0.3s linear;
  }

  &:hover {
    .other-project__title,
    .other-project__desc {
      transform: translateY(0);
      opacity: 1;
    }
    .project__links {
      transform: scale(0.85) translateY(0);
      opacity: 1;
    }
    &::before {
      opacity: 1;
    }
  }
}

.other-project__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 40% top;
}

@media (max-width: 991px) {
  .other-project {
    .other-project__title,
    .other-project__desc {
      transform: translateY(0);
      opacity: 1;
    }
    .project__links {
      transform: scale(0.85) translateY(0);
      opacity: 1;
    }
  }
}
