.contact-wrapper {
  display: flex;
  // align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .portrait {
    @media (max-width: 991px) {
      // display: none;
      margin-bottom: 3rem;
    }
  }
}

.contact-detail {
  width: 40%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.email-logo {
  width: 80px;
  filter: invert(1);

  @media (max-width: 991px) {
    display: none;
  }
}

.contact-form {
  margin-left: 7rem;
  padding-right: 5rem;
  width: 60%;

  @media (max-width: 991px) {
    margin-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.contact__title {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.contact__desc {
  line-height: 1.8;
  margin: 2rem 0 2.5rem 0;
}
