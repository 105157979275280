.form-group {
  margin-bottom: 1.5rem;
  label {
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.text-red {
  color: $heart-red;
  font-size: 16px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba($dark-body-lighter, 0);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: $dark-accent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba($dark-accent, 0.25);
  }
}

.textarea {
  resize: vertical;
}

.field-error {
  color: $heart-red;
  font-size: 12px;
}

.alert {
  padding: 15px;
  margin-bottom: 30px;
  background-color: rgba($dark-accent, 0.5);
  font-size: 14px;
}
