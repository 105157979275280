@import "./styles/sizes";
@import "./styles/colors";
@import "./styles/base";
@import "../node_modules/hamburgers/dist/hamburgers.min.css";
@import "./styles/navbar";
@import "./styles/button";
@import "./styles/header";
@import "./styles/portrait";
@import "./styles/about";
@import "./styles/skills";
@import "./styles/experience";
@import "./styles/projects";
@import "./styles/contact";
@import "./styles/form";
@import "./styles/footer";
@import "./styles/widgets";
