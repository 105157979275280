.company__works {
  padding: 0;
  list-style-type: none;
  margin: 30px 0 0 0;

  .work {
    margin: 0 0 25px 0;
    font-size: 0.95rem;
    display: flex;
    align-items: flex-start;
    line-height: 1.8;
    width: 100%;
    max-width: 850px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .work__arrow {
    border: 6px solid transparent;
    border-left-color: #ffb633;
    margin-right: 8px;
    display: inline-block;
    margin-top: 8px;
  }
}

.timeline {
  border-left: 1px solid $dark-muted;
}

.company {
  position: relative;
  margin-left: 6%;
  padding: 20px 0;
}

.company::before {
  position: absolute;
  content: "";
  top: 42px;
  left: calc(-6% - 3px);
  width: 3%;
  height: 1px;
  background: $dark-muted;
}

.company__intro {
  display: flex;
  font-size: 1.2rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.company__designation {
  font-weight: 700;
  margin-left: 1rem;
  color: $dark-accent;

  @media (max-width: 767px) {
    margin-left: 0rem;
  }
}

.company__period {
  margin: 15px 0;
  color: $dark-muted;
  font-size: 1.1rem;
}

.work__desc a {
  text-decoration: none;
  color: $dark-accent;
}
