.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $m-size $l-size;
  position: fixed;
  width: 100%;
  background: rgba($dark-body, .7);
  z-index: 3;
  backdrop-filter: blur(5px);
  @media (max-width: 991px) {
    padding: 1.5rem 1.8rem;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    padding: .8rem 1.5rem;
  }
}

.hamburger {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-active {
    z-index: 3;
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background-color: $dark-accent;
    }
  }
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: $dark-accent;
}

@media (min-width: 992px) {
  .mobile-menu-trigger {
    display: none;
  }
}

.navbar-brand {
  svg {
    width: 40px;
    height: 40px;
    @media (max-width: 1199px) {
      width: 35px;
      height: 35px;
    }
  }
}

.navbar-list {
  padding: 0;
  list-style-type: none;
  display: flex;
  margin: 0;

  @media (max-width: 991px) {
    flex-direction: column;
    text-align: center;
  }
}

.navbar-link {
  padding: 0.7rem 1.5rem;
  text-decoration: none;
  font-size: 0.95rem;
  display: inline-block;
  position: relative;
  color: $dark-text;
  transition: all 0.2s linear;
  @media (max-width: 991px) {
    font-size: 1.5rem;
    padding: 1.5rem 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 1.2rem;
    padding: 1.2rem 1.5rem;
  }
  &.active {
    color: $dark-accent;
    &:hover::before {
      background: transparent;
    }
  }
  &::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    right: auto;
    display: block;
    background: transparent;
    transition: all 0.2s linear;
  }
  &:hover {
    color: $dark-accent;
    &::before {
      background: rgba($dark-accent, 0.1);
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.navbar {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    position: fixed;
    right: 0;
    top: 0;
    width: 65%;
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    transition: all 0.2s linear;
    transform: translateX(100%);
    height: 100vh;
    justify-content: center;

    &.is-active {
      transform: translateX(0);
      background: rgba($dark-body, 0.9);
    }
  }
}

.resume-link {
  margin-left: 1.5rem;

  @media (max-width: 991px) {
    margin: 1.5rem 0 0 0;
  }

  @media (max-width: 767px) {
    margin: 1.2rem 0 0 0;
  }

  .btn-outlined {
    padding: 0.7rem 1.5rem;
    text-decoration: none;
    font-size: 0.95rem;
    display: inline-block;

    @media (max-width: 991px) {
      font-size: 1.5rem;
    }

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
}
